<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Supplier</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Supplier"
              >
                <b-form-input
                  v-model="supplier"
                  placeholder="Enter Supllier Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>GST No.</label>
              <b-form-input
                v-model="gstno"
                type="text"
                placeholder="Enter GST No."
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Invoice Date"
              >
                <label>Invoice Date</label>
                <label style="color: red !important">*</label>
                <flat-pickr
                  v-model="invoicedate"
                  placeholder="Select Invoice Date"
                  class="form-control"
                  :config="{
                    dateFormat: 'd/m/Y',
                    maxDate: new Date(),
                  }"
                  style="background-color: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Branch</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Branch"
              >
                <v-select
                  placeholder="None"
                  :options="['Channel Partner', 'Franchise', 'Admin']"
                  v-model="branchname"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Location</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Loaction"
              >
                <b-form-input v-model="location" placeholder="Enter Location" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>DLR No</label>
              <b-form-input v-model="dlrno" placeholder="Enter DLR No" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>DLR Date</label>
              <flat-pickr
                v-model="dlrdate"
                placeholder="Select DLR Date"
                class="form-control"
                :config="{
                  dateFormat: 'd/m/Y',
                  maxDate: new Date(),
                }"
                style="background-color: transparent"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Activity Month</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Activity Month"
              >
                <b-form-input
                  v-model="activitymonth"
                  placeholder="Enter Activity Month"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Prod./Model</label>
              <b-form-input
                v-model="prodmodel"
                placeholder="Enter Prod./Model"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                rules="required|digits:6"
                name="Supplier Pincode"
              >
                <label>Supplier Pincode</label>
                <label style="color: red !important">*</label>
                <b-form-input
                  maxlength="6"
                  v-model="supplierpincode"
                  placeholder="Enter Supplier Pincode"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <table class="table table-bordered">
              <thead class="text">
                <tr>
                  <th>Sr. No</th>
                  <th>Attachment Name</th>
                  <th>Attachment</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, id) in documents" :key="id">
                  <td>{{ id + 1 }}</td>
                  <td>
                    <b-form-input
                      v-model="item.attachmentname"
                      placeholder=" Enter Attachment Name"
                    />
                  </td>
                  <td>
                    <b-form-file
                      v-model="item.attachment"
                      placeholder="Select Document"
                      drop-placeholder="Drop file here..."
                      @input="handleChange($event, 'activities', id)"
                    />
                    <attachment :data="item.attachment" />
                  </td>
                  <td>
                    <b-button variant="outline-danger" @click="removeRow(id)">
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
            <b-button variant="primary" @click="addRow" class="my-1">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Add More</span>
            </b-button>
          </b-col>
          <!-- submit button -->
          <b-col cols="12">
            <b-button
              :disabled="flag || submitDisable"
              variant="primary"
              type="submit"
              @click.prevent="submitForm"
              class="mr-4"
            >
              Submit
            </b-button>
            <b-button variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <!-- <template #code>
      {{ codeType }}
    </template> -->
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from '@/components/axios';
import moment from "moment";
import Attachment from "../../../components/Attechment.vue";

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    Attachment,
  },
  data() {
    return {
      supplier: "",
      gstno: "",
      invoicedate: moment(new Date()).format("DD/MM/yyyy"),
      branchname: "",
      location: "",
      dlrno: "",
      dlrdate: moment(new Date()).format("DD/MM/yyyy"),
      activitymonth: "",
      prodmodel: "",
      supplierpincode: "",
      documents: [
        {
          attachmentname: "",
          attachment: "",
        },
      ],
      flag: false,
      submitDisable: false,
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Activities") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/crm/activities");
            }
          } else if (item.add !== 1) {
            this.$router.push("/crm/activities");
          }
        }
      });
    }
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getactivityById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(data) {
      data.map((item) => {
        this.supplier = item.supplier;
        this.gstno = item.gstno;
        this.invoicedate = item.invoicedate;
        this.branchname = item.branch;
        this.location = item.location;
        this.dlrno = item.dlrno;
        this.dlrdate = item.dlrdate;
        this.activitymonth = item.activitymonth;
        this.prodmodel = item.prodmodel;
        this.supplierpincode = item.supplierpincode;
        this.documents = item.ActivityDocuments;
      });
    },
    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            company: 1,
            active: 1,
            supplier: this.supplier,
            gstno: this.gstno,
            invoicedate: this.invoicedate,
            branch: this.branchname,
            location: this.location,
            dlrno: this.dlrno,
            dlrdate: this.dlrdate,
            activitymonth: this.activitymonth,
            prodmodel: this.prodmodel,
            supplierpincode: this.supplierpincode,
            data: this.documents,
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the All Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/activity/${this.$route.params.id}`
              : `${baseApi}/activity`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;

              this.$swal({
                title: "Submited",
                text: json.data.message
                  ? `${json.data.message}`
                  : json.data.success
                  ? `${json.data.success}`
                  : "Update SuccessFully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              // console.log(json, "json====");
              this.$router.push("/crm/activities");
            })
            .catch((error) => {
              this.submitDisable = false;
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    addRow() {
      this.documents.push({
        attachmentname: "",
        attachment: "",
      });
    },
    removeRow(index) {
      this.documents.splice(index, 1);
    },
    async handleChange(e, type, index) {
      this.flag = true;

      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };

      await axios(requestOptions)
        .then((response) => {
          this.flag = false;
          this.documents[index].attachment =  process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"));
    },
    onClickBack() {
      this.$router.push("/crm/activities");
    },
  },
};
</script>
